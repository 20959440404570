/*
 * Copyright © Ergonode Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */
<template>
    <div class="horizontal-tab-bar-content">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'HorizontalTabBarContent',
};
</script>

<style lang="scss" scoped>
    .horizontal-tab-bar-content {
        display: flex; 
        height: 100%;
       /* background-color: $WHITE;
        border-top: $BORDER_1_GREY; */
        -webkit-box-shadow: 0 2px 30px 0 rgba(31, 38, 103, 0.2);
        box-shadow: 0 2px 30px 0 rgba(31, 38, 103, 0.2);
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.37);
        border: 1.5px solid #fff;
    }
</style>
